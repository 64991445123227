import React, { useState, useEffect } from "react"
import { createChart } from "lightweight-charts"
import { getPortfolioValues } from "../utils/apiWrapper"
import "../styles/chart.css"

export default function Chart(props) {
  const { accountValue, setPNL } = props

  const [currActiveItem, setCurrActiveItem] = useState("1D")
  const [currChart, setCurrChart] = useState(null)
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const [dayData, setDayData] = useState(null)
  const [weekData, setWeekData] = useState(null)
  const [monthData, setMonthData] = useState(null)
  const [yearData, setYearData] = useState(null)

  const intervals = ["1D", "1W", "1M", "1Y"]

  const seriesesData = new Map([
    ["1D", dayData],
    ["1W", weekData],
    ["1M", monthData],
    ["1Y", yearData],
  ])

  useEffect(() => {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)

    const getData = async () => {
      const resp = await getPortfolioValues()

      let temp = []
      for (let index in resp["data"]["day"]) {
        temp.push({
          time: resp["data"]["day"][index]["time"],
          value: resp["data"]["day"][index]["value"],
        })
      }

      setDayData(temp)
      setPNL(
        Math.round(
          (temp[temp.length - 1]["value"] - temp[0]["value"] + Number.EPSILON) *
            10
        ) / 10
      )

      temp = []
      for (let index in resp["data"]["week"]) {
        temp.push({
          time: resp["data"]["week"][index]["time"],
          value: resp["data"]["week"][index]["value"],
        })
      }

      setWeekData(temp)

      temp = []
      for (let index in resp["data"]["month"]) {
        temp.push({
          time: resp["data"]["month"][index]["time"],
          value: resp["data"]["month"][index]["value"],
        })
      }

      setMonthData(temp)

      temp = []
      for (let index in resp["data"]["year"]) {
        temp.push({
          time: resp["data"]["year"][index]["time"],
          value: resp["data"]["year"][index]["value"],
        })
      }

      setYearData(temp)
    }

    getData()
    setInterval(getData, 60000)
    
    // eslint-disable-next-line
  }, [])

  let areaSeries = null
  useEffect(() => {
    const syncToInterval = () => {
      let chartElement = document.createElement("div")
      let chart = createChart(chartElement, {
        width: width * 0.8,
        height: height * 0.43,
        layout: {
          backgroundColor: "#131720",
          textColor: "#d1d4dc",
        },
        grid: {
          vertLines: {
            visible: false,
          },
          horzLines: {
            visible: false,
          },
        },
        rightPriceScale: {
          borderVisible: false,
        },
        timeScale: {
          timeVisible:
            currActiveItem === "1D" ||
            currActiveItem === "1W" ||
            currActiveItem === "1M",
          secondsVisible: false,
          tickMarkFormatter: time => {
            let d = new Date(0)
            d.setUTCSeconds(time + 18000)
            if (
              currActiveItem === "1D" ||
              currActiveItem === "1W" ||
              currActiveItem === "1M"
            ) {
              return `${d.getMonth() + 1}/${d.getDate()} ${
                d.getHours() < 10 ? `0${d.getHours()}` : d.getHours()
              }:${d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes()}`
            } else if (currActiveItem === "1Y") {
              return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`
            }
          },
        },
      })

      setCurrChart(chartElement)

      if (areaSeries) {
        chart.removeSeries(areaSeries)
        areaSeries = null
      }
      // eslint-disable-next-line
      areaSeries = chart.addAreaSeries({
        topColor: "rgba(0, 216, 154, 0.56)",
        bottomColor: "rgba(0, 216, 154, 0.04)",
        lineColor: "rgba(0, 216, 154, 1)",
        lineWidth: 2,
      })

      areaSeries.setData(seriesesData.get(currActiveItem))
      chart.timeScale().fitContent()
    }

    if (dayData !== null) {
      syncToInterval()
    }
  }, [currActiveItem, width, height, dayData, weekData, monthData, yearData])

  useEffect(() => {
    if (typeof window === "undefined") {
      return
    }

    const handleResize = () => {
      setWidth(window.innerWidth)
      setHeight(window.innerHeight)
    }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  })

  return (
    <div className="stock-chart">
      <div className="chart">
        <div className="chart-header">
          <div className="stock-chart-price">${accountValue}</div>
        </div>
        <div
          ref={nodeElement => {
            nodeElement &&
              currChart &&
              (nodeElement.hasChildNodes()
                ? nodeElement.replaceChild(currChart, nodeElement.firstChild)
                : nodeElement.appendChild(currChart))
          }}
        />
        {intervals.map(interval => (
          <button
            key={interval}
            value={interval}
            className="switcher-item"
            onClick={e => setCurrActiveItem(e.target.value)}
          >
            {interval}
          </button>
        ))}
      </div>
      <br />
    </div>
  )
}
