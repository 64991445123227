import React, { useState, useEffect } from "react"
import MaterialUI from "./components/layout/material"
import { withStyles, makeStyles } from "@material-ui/core/styles"
import { Header, Chart, MaterialTable } from "./components"
import { Typography } from "@material-ui/core"
import LoadingOverlay from "react-loading-overlay"
import { getDetails } from "./utils/apiWrapper"

const PnLTypographyGreen = withStyles({
  root: {
    color: "#00d89a",
    fontFamily: "Crimson Text",
    display: "inline-block",
  },
})(Typography)

const PnLTypographyRed = withStyles({
  root: {
    color: "#f14c00",
    fontFamily: "Crimson Text",
    display: "inline-block",
  },
})(Typography)

export default function Home() {
  const [PNL, setPNL] = useState(0)
  const [accountValue, setAccountValue] = useState(0)
  const [holdingRows, setHoldingRows] = useState(null)
  const [tradesRows, setTradesRows] = useState(null)
  const [width, setWidth] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [loadedOnce, setLoadedOnce] = useState(false)

  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    welcome: {
      marginLeft: theme.spacing(9),
      marginTop: theme.spacing(6),
      fontFamily: "Crimson Text",
      width: width * 0.2,
    },
    status: {
      marginLeft: theme.spacing(9),
      marginTop: theme.spacing(1),
      fontFamily: "Crimson Text",
      width: width * 0.23,
    },
    text: {
      display: "inline-block",
    },
    tables: {
      display: "inline-block",
    },
  }))

  const classes = useStyles()

  useEffect(() => {
    setWidth(window.innerWidth)
  }, [])

  useEffect(() => {
    if (typeof window === "undefined") {
      return
    }

    const handleResize = () => {
      setWidth(window.innerWidth)
    }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  })

  useEffect(() => {
    const saveDetails = async () => {
      const resp = await getDetails()

      setAccountValue(
        Math.round(
          (resp["data"]["currentAccountValue"] + Number.EPSILON) * 100
        ) / 100
      )

      const quantities = resp["data"]["currQuantities"]
      let rows = []
      for (let pair of Object.keys(quantities)) {
        const symbol = pair.split("/")[0]
        const percent = quantities[pair][1]
        const value =
          (quantities[pair][1] / 100) * resp["data"]["currentAccountValue"]
        const trend = (
          Math.round((resp["data"]["trend"][pair] + Number.EPSILON) * 100) / 100
        ).toString()

        rows.push([
          symbol,
          Math.round((quantities[pair][0] + Number.EPSILON) * 1000000) /
            1000000,
          percent < 10
            ? Math.round((percent + Number.EPSILON) * 1000) / 1000
            : Math.round((percent + Number.EPSILON) * 100) / 100,
          `$${Math.round((value + Number.EPSILON) * 100) / 100}`,
          `${
            pair === "USD/USD" ? "n/a" : trend >= 0 ? `+${trend}%` : `${trend}%`
          }`,
        ])
      }
      setHoldingRows(rows)

      const trades = resp["data"]["recentTrades"]
      rows = []
      for (let trade of trades) {
        const symbol = trade["symbol"].split("/")[0]
        let time = trade["datetime"].split("T")[1].split(".")[0]

        let hour = parseInt(time.substring(0, 2))
        hour = hour - 5 < 0 ? 24 + hour - 5 : hour - 5
        hour = hour < 10 ? `0${hour.toString()}` : hour.toString()

        time = hour + time.substring(2)

        const cost = Math.round((trade["cost"] + Number.EPSILON) * 100) / 100

        rows.push([
          time,
          symbol,
          trade["side"].toUpperCase(),
          trade["price"],
          trade["amount"],
          `$${cost.toString()}`,
        ])
      }
      setTradesRows(rows)

      // eslint-disable-next-line
      setIsLoading(tradesRows && holdingRows)
      setLoadedOnce(true)
    }

    saveDetails()
    setInterval(saveDetails, 20000)

    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <MaterialUI>
        <Header />
      </MaterialUI>{" "}
      <LoadingOverlay
        active={!loadedOnce && isLoading}
        spinner
        text="Compiling data..."
      >
        <div>
          <div className={classes.text}>
            <Typography variant="h3" className={classes.welcome}>
              Welcome,{" "}
            </Typography>{" "}
            <Typography variant="h5" className={classes.status}>
              krypton's 24hr profit and loss is{" "}
              {PNL >= 0 ? (
                <PnLTypographyGreen variant="h5"> +{PNL}%</PnLTypographyGreen>
              ) : (
                <PnLTypographyRed variant="h5">{PNL}%</PnLTypographyRed>
              )}{" "}
            </Typography>{" "}
          </div>{" "}
          <div className={classes.tables}>
            {tradesRows && (
              <MaterialTable
                label="trades-table"
                headers={["Time", "Symbol", "Side", "Price", "Filled", "Total"]}
                rows={tradesRows}
                width={width * 0.35}
                caption={"Our recent trades (24h limit)"}
                spacing={6}
              />
            )}{" "}
            {holdingRows && (
              <MaterialTable
                label="holding-table"
                headers={[
                  "Symbol",
                  "Quantity",
                  "Percent",
                  "Value",
                  "15m Trend",
                ]}
                rows={holdingRows}
                width={width * 0.3}
                caption={"Our current holdings"}
                spacing={0}
              />
            )}{" "}
          </div>{" "}
        </div>{" "}
        <Chart accountValue={accountValue} setPNL={pnl => setPNL(pnl)} />{" "}
      </LoadingOverlay>
    </div>
  )
}
