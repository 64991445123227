/* eslint-disable */

import axios from "axios"

const BASE_URL =
  process.env.NODE_ENV === "production"
    ? "https://cardinal.vercel.app/api"
    : "http://localhost:3000/api"

export const getPortfolioValues = () => {
  /**
   * Returns all portfolio vals
   * Returns GET_VALUES_FAIL upon failure
   */

  return axios
    .get(`${BASE_URL}/portfolio/values`, {
      headers: {
        "Content-Type": "application/JSON",
      },
    })
    .catch(error => {
      ;({
        type: "GET_VALUES_FAIL",
        error,
      })
    })
}

export const getDetails = () => {
  /**
   * Returns account details
   * Returns GET_DETAILS_FAIL upon failure
   */

  return axios
    .get(`${BASE_URL}/portfolio/details`, {
      headers: {
        "Content-Type": "application/JSON",
      },
    })
    .catch(error => {
      ;({
        type: "GET_DETAILS_FAIL",
        error,
      })
    })
}
