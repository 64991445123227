import React from "react"
import { AppBar, Toolbar, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    marginLeft: theme.spacing(6),
    fontWeight: "bold",
    letterSpacing: 1,
  },
  logout: {
    flexGrow: 1,
    fontSize: 15,
  },
}))

/**
 * @return {AppBar} with navigational links
 */
export default function Header() {
  const classes = useStyles()

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h4" className={classes.title}>
          Portfolio
        </Typography>
      </Toolbar>
    </AppBar>
  )
}
