import React from "react"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

export default function MaterialTable(props) {
  const { label, headers, rows, width, spacing, caption } = props

  const useStyles = makeStyles(theme => ({
    table: {
      minWidth: 50,
      width: width,
    },
    body: {
      minWidth: 50,
      width: width * 1.02,
      backgroundColor: "#131720",
      display: "inline-block",
      marginTop: theme.spacing(3),
      marginRight: theme.spacing(spacing),
      marginLeft: theme.spacing(spacing * 0.3),
      verticalAlign: "middle",
    },
    text: {
      color: "#ffffff",
    },
    header: {
      backgroundColor: "#000000",
    },
  }))

  const classes = useStyles()

  return (
    <TableContainer component={Paper} className={classes.body}>
      <Table className={classes.table} size="small" aria-label={label}>
        <caption>
          <Typography className={classes.text}>{caption}</Typography>
        </caption>
        <TableHead className={classes.header}>
          <TableRow>
            {headers.map(header => (
              <TableCell align="left">
                <Typography className={classes.text}>{header}</Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row[0]}>
              {row.map(cell => (
                <TableCell component="th" scope="row">
                  <Typography className={classes.text}>{cell}</Typography>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
